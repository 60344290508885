import { ColorVariant } from '../../../types/featuredBenefitBlockType';

export function getLabelVariant(variant?: ColorVariant) {
  switch (variant) {
    case 'signature':
      return 'signature';
    case 'secondary':
      return 'moss';
    case 'outline':
      return 'white';
    case 'spruce':
      return 'spruce';
    default:
      return 'signature';
  }
}
